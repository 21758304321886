/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* width */
.styled-scroll::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.styled-scroll::-webkit-scrollbar-track {
    /* background: #f1f1f178; */
}

/* Handle */
.styled-scroll::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
.styled-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.daterangepicker {
    font-family: system-ui !important;
}

.daterangepicker .ranges li.active {
    background-color: #3eb6e4 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #3eb6e4 !important;
}
