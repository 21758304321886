.App {
    box-sizing: border-box;
    list-style: none;
}

body {
    width: calc(100vw - 8px);
}

.navbarStyle {
    padding: 0.5rem 1rem !important;
}
